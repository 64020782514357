export function isHalfWidthChar(str: string): boolean {
  const chr = str.charCodeAt(0);

  if ((chr >= 0x00 && chr < 0x81) ||
       (chr === 0xf8f0) ||
       (chr >= 0xff61 && chr < 0xffa0) ||
       (chr >= 0xf8f1 && chr < 0xf8f4)) {
    return true;
  } else {
    return false;
  }
}
