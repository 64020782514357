export function dreamBgColorPipe(id: number): string {
  switch (id % 5) {
    case 0:
      return '#EF5BA1';

    case 1:
      return '#FBB03F';

    case 2:
      return '#295CAA';

    case 3:
      return '#00C1F3';

    case 4:
      return '#72BF44';

    default:
      return '#bdbdbd';
  }
}
