import React from 'react';
import { LinkMapData, LinkMapNode } from '../utils/interface/LinkMapData';
import * as d3 from 'd3';
import { Selection } from 'd3';
import { isHalfWidthChar } from '../utils/function/isHalfWidthChar';
import { dreamBgColorPipe } from '../utils/pipe/dreamBgColorPipe';
import request from '../utils/function/request';
import Config from '../configs/app.config';

interface LinkMapProps {
}

const LinkMap: React.FC<LinkMapProps> = (props) => {

  const ref = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    request<LinkMapData>({
      url: '/v1/linkmap'
    }).subscribe(
      res => {
        const svgHeight = ref.current?.clientHeight;
        const data: LinkMapData = {
          nodes: res.data.nodes.map(node => ({
            ...node,
            x: window.innerWidth / 2,
            y: svgHeight ? svgHeight / 2 : 0,
            ...node.center && {
              fx: window.innerWidth / 2,
              fy: svgHeight ? svgHeight / 2 : 0
            }
          })),
          links: res.data.links
        }
        createLinkMap(data)
      },
      error => {}
    )
  }, []);

  const handleClick = (d: LinkMapNode) => {
    const uri = `${Config.APP_URL}/${d.node_type}/${d.node_id}`;
    window.open(uri);
  }

  // ノードの高さを取得
  const nodeHeight = (node: LinkMapNode) => node.center ? 80 : 50;

  // ノードの幅を取得
  const nodeWidth = (node: LinkMapNode) => {
    if (node.center) {
      return 80;
    }
    return node.node_type === 'dream'
      ? 116.666666667
      : 50;
  };

  // ノードの角丸の半径を取得
  const nodeRadius = (node: LinkMapNode) => {
    return node.node_type === 'user'
      ? nodeHeight(node) / 2
      : 3;
  }

  // ノードのテキストを改行
  const textWrap = (text: Selection<SVGTextElement, LinkMapNode, SVGElement, unknown>, width: number) => {
    text.each(function() {
      const text = d3.select(this);
      const words: string[] = [];
      const splitedWords = (() => {
        const words = text.text().split(/\s+/);
        const withSpace: string[] = [];
        words.forEach((word, index) => {
          if (index !== 0) {
            withSpace.unshift(' ');
          }
          withSpace.unshift(word);
        })
        return withSpace;
      })();

      splitedWords.forEach((splitedWord, i) => {
        let halfWidth = '';

        for (let j = splitedWord.length - 1; 0 <= j; j--) {
          const str = splitedWord.substr(j, 1);
          if (isHalfWidthChar(str)) {
            halfWidth = str + halfWidth;
          } else {
            if (halfWidth !== '') {
              words.push(halfWidth);
              halfWidth = '';
            }
            words.push(str);
          }
        }

        if (halfWidth !== '') {
          words.push(halfWidth);
        }
      })

      let line: string[] = [];
      let lineNumber = 0;
      let lineHeight = 1.4; // ems
      const x = text.attr('x');
      const y = text.attr('y');
      const dy = 0; //parseFloat(text.attr("dy")),
      let tspan = text.text(null)
                      .append('tspan')
                      .attr('x', x)
                      .attr('y', y)
                      .attr('dy', dy + 'em')
                      .attr('style', 'user-select: none');

      let word = words.pop();

      while (word) {
        line.push(word);
        tspan.text(line.join(''));
        const tspanNode = tspan.node();
        if (tspanNode && tspanNode.getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(''));
            line = [word];
            tspan = text.append('tspan')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                        .attr('style', 'user-select: none')
                        .text(word);
        }

        word = words.pop();
      }
    });
  }

  // リンクマップを作成
  const createLinkMap = (data: LinkMapData) => {
    if (ref.current && !ref.current.hasChildNodes()) {
      const width = window.innerWidth,
            height = ref.current.clientHeight;

      const svg = d3.select(ref.current)
                    .on('dblclick.zoom', null);

      svg.selectAll('g').remove();

      const g = svg.append('g');

      const link = g.append('g')
                      .selectAll('line')
                      .data(data.links)
                      .join('line')
                      .attr('stroke', '#d3d3d3')
                      .attr('stroke-width', 2);

      const node = g.append('g')
                      .selectAll('g')
                      .data(data.nodes)
                      .join('g')
                      .on('click', handleClick);

      // 画像の切り抜き用の図形
      node.append('defs')
          .append('clipPath')
            .attr('id', d => d.id)
            .append('rect')
              .attr('width', d => nodeWidth(d))
              .attr('height', d => nodeHeight(d))
              .attr('rx', d => nodeRadius(d))
              .attr('ry', d => nodeRadius(d))
              .attr('stroke', '#000000')
              .attr('stroke-width', 3)
              .attr('fill', '#ffffff');

      // 画像の背景
      node.append('rect')
          .attr('width', d => nodeWidth(d))
          .attr('height', d => nodeHeight(d))
          .attr('rx', d => nodeRadius(d))
          .attr('ry', d => nodeRadius(d))
          .attr('fill', d => d.node_type === 'dream' ? dreamBgColorPipe(d.node_id) : '#bdbdbd');

      // 画像
      node.append('image')
          .attr('width', d => nodeWidth(d))
          .attr('height', d => nodeHeight(d))
          .attr('preserveAspectRatio', 'xMidYMid slice')
          .attr('clip-path', d => `url(#${d.id})`)
          .attr('xlink:href', d => d.thumbnail_img);

      // ラベル
      node.append('text')
          .attr('text-anchor', 'middle')
          .attr('fill', '#4d4d4d')
          .attr('font-size', '11px')
          .attr('x', d => nodeWidth(d) / 2)
          .attr('y', d => nodeHeight(d) + 15)
          .text(d => d.name)
          .call(textWrap, 180)

      // 枠線
      node.append('rect')
          .attr('width', d => nodeWidth(d))
          .attr('height', d => nodeHeight(d))
          .attr('rx', d => nodeRadius(d))
          .attr('ry', d => nodeRadius(d))
          .attr('fill', 'none')
          .attr('stroke', '#ffffff')
          .attr('stroke-width', 3)

      const ticked = () => {
        link.attr('x1', (d: any) => d.source.x)
            .attr('y1', (d: any) => d.source.y)
            .attr('x2', (d: any) => d.target.x)
            .attr('y2', (d: any) => d.target.y);

        node.attr('transform', (d: any) => `translate(${d.x - nodeWidth(d) / 2}, ${d.y - nodeHeight(d) / 2})`);
      };

      svg.call(
        d3.zoom<SVGSVGElement, unknown>()
          .on('zoom', () => g.attr('transform', d3.event.transform))
          .scaleExtent([0.25, 3])
      )
      .on('dblclick.zoom', null);

      const simulation = d3.forceSimulation(data.nodes)
                            .on('tick', ticked)
                            .force('link', d3.forceLink(data.links)
                                              .id((d: any) => d.id)
                                              .distance(0)
                                              .strength(0.7)
                            )
                            .force('center', d3.forceCenter(width / 2, height / 2))
                            .force('collide', d3.forceCollide()
                                                .radius((d: any) => data.nodes.length === 2
                                                  ? nodeWidth(d) * 4
                                                  : nodeWidth(d)
                                                )
                                                .strength(0.15)
                            );

      simulation.alphaDecay(1 - Math.pow(0.015, 1/300))
    }
  }

  return (
    <>
      <svg ref={ ref }
        style={{
          backgroundColor: '#ededed',
          width: '100%',
          height: '100%',
        }}
      />
    </>
  );
}

export default LinkMap;
