import React from 'react';
import LinkMap from './components/LinkMap';

const App: React.FC = () => {
  return (
    <>
      <LinkMap />
    </>
  );
}

export default App;
