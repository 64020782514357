import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from '../../configs/app.config';
import { Observable, from } from 'rxjs';
import { take } from 'rxjs/operators';

export const client = (() => {
  const _client = axios.create({
    baseURL: Config.API,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
  });

  return _client;
})();

export default function request<T = any>(reqConfig: AxiosRequestConfig): Observable<AxiosResponse<T>> {
  return from(client.request<T>({...reqConfig})).pipe(take(1));
};
